import { CaptivePageLayout } from '@meterup/metric';
import { api } from '@meterup/proto';
import { Formik } from 'formik';
import React from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { Route, Switch, useHistory } from 'react-router-dom';
import { toFormikValidationSchema } from 'zod-formik-adapter';

import type { OnboardingData } from '../../../api/types';
import type { OnboardingFormData } from './types';
import { fetchOnboarding, updateOnboarding } from '../../../api/api';
import { useCurrentCompany } from '../../../providers/CurrentCompanyProvider';
import { routes } from '../../../routes';
import { isDefined } from '../../../utils/isDefined';
import { logError } from '../../../utils/logError';
import { Footer } from '../Footer';
import { useCurrentCompanyLocation } from '../useCurrentCompanyLocation';
import { AutoPersistFormData } from './components/AutoPersistFormData';
import { apiDataToFormData, formDataToAPIData } from './data';
import { Header } from './Header';
import { SideContent } from './SideContent';
import { GuestNetworkStep } from './steps/GuestNetworkStep';
import { ISPDetailsStep } from './steps/ISPDetailsStep';
import { MeterInstallStep } from './steps/MeterInstallStep';
import { NetworkSetupStep } from './steps/NetworkSetupStep';
import { validate, validOnboardingFormData } from './validations';

interface OnboardingFormProps {
  onboarding: OnboardingData;
}

const OnboardingForm: React.FC<OnboardingFormProps> = ({ onboarding }) => {
  const history = useHistory();

  const queryClient = useQueryClient();

  const handleSubmit = useMutation(
    (values: OnboardingFormData) => {
      const data = formDataToAPIData(onboarding, values);
      data.status = api.OnboardingStatus.information_complete;
      return updateOnboarding(data);
    },
    {
      onSuccess: () => history.push(routes.setup.location.root.pathTo(onboarding.sublocation_sid)),
      onError: logError,
      onSettled: () => {
        queryClient.invalidateQueries('onboarding');
      },
    },
  );

  return (
    <Formik<OnboardingFormData>
      validate={validate}
      validationSchema={toFormikValidationSchema(validOnboardingFormData)}
      initialValues={apiDataToFormData(onboarding)}
      onSubmit={(values) => handleSubmit.mutate(values)}
    >
      {(form) => (
        <>
          <AutoPersistFormData onboarding={onboarding} />
          <CaptivePageLayout
            content={
              <form onSubmit={form.handleSubmit}>
                <Header />
                <Switch>
                  <Route path={routes.setup.location.form.isp.path} component={ISPDetailsStep} />
                  <Route
                    path={routes.setup.location.form.mainNetwork.path}
                    component={NetworkSetupStep}
                  />
                  <Route
                    path={routes.setup.location.form.guestNetwork.path}
                    component={GuestNetworkStep}
                  />
                  <Route
                    path={routes.setup.location.form.meterInstall.path}
                    component={MeterInstallStep}
                  />
                </Switch>
              </form>
            }
            sideContent={<SideContent />}
            footer={<Footer />}
          />
        </>
      )}
    </Formik>
  );
};

export const OnboardingFormContainer: React.FC = () => {
  const company = useCurrentCompany();
  const location = useCurrentCompanyLocation();
  const onboarding = useQuery(
    ['onboarding', location?.sublocation_sid],
    () => fetchOnboarding(company, location!.sublocation_sid),
    { enabled: isDefined(location) },
  ).data;

  return onboarding ? (
    <OnboardingForm onboarding={onboarding} />
  ) : (
    <CaptivePageLayout content={<div />} sideContent={<SideContent />} footer={<Footer />} />
  );
};

import { Alert, Body2, BodyMono2, Heading2, Link, Small2, Tab, Tabs } from '@meterup/metric';
import React, { useState } from 'react';

import type { DisplayableError } from '../../errors';
import { isDisplayableError } from '../../errors';
import { colors, styled } from '../../stitches';
import {
  CloseDrawerButton,
  Drawer,
  DrawerContent,
  DrawerControls,
  DrawerHeader,
  DrawerTitle,
} from '../Drawer/Drawer';

const ErrorFallbackContainer = styled('div', {
  vStack: '$16',
  alignItems: 'stretch',
  width: '100%',
  height: '100%',
  padding: '$20',
});

const DevelopmentErrorFallbackHeading = styled(Heading2, {
  color: colors['red-800'],
});

const CodeBlock = styled('pre', BodyMono2, {
  padding: '$8',
  backgroundColor: colors['gray-50'],
  overflow: 'auto',
  borderRadius: '$4',
});

const DevelopmentErrorFallback = ({
  error,
  componentStack,
}: {
  error: Error;
  componentStack: string | null;
}) => {
  const [currentStack, setCurrentStack] = useState<'error' | 'component'>('error');
  return (
    <ErrorFallbackContainer>
      <div>
        <DevelopmentErrorFallbackHeading>
          Uncaught error: {error.name}
        </DevelopmentErrorFallbackHeading>

        <Body2>{error.message}</Body2>
      </div>
      <Tabs>
        <Tab active={currentStack === 'error'} onClick={() => setCurrentStack('error')}>
          Stack Trace
        </Tab>
        <Tab active={currentStack === 'component'} onClick={() => setCurrentStack('component')}>
          Component Stack
        </Tab>
      </Tabs>
      {currentStack === 'error' ? (
        <CodeBlock>{error.stack}</CodeBlock>
      ) : (
        <CodeBlock>{componentStack}</CodeBlock>
      )}
      <Small2>This error would be logged to Sentry in staging or production.</Small2>
    </ErrorFallbackContainer>
  );
};

export const RefreshOrContactSupportBoilerplate = () => (
  <>
    Please try refreshing the page. If the issue persists, please{' '}
    <Link href="https://www.meter.com/support">contact support</Link>.
  </>
);

export interface ErrorFallbackProps {
  error: Error;
  // eslint-disable-next-line react/no-unused-prop-types
  componentStack: string | null;
}

export const ErrorFallback = ({ error, componentStack }: ErrorFallbackProps) => (
  <ErrorFallbackContainer>
    {import.meta.env.NODE_ENV === 'development' ? (
      <DevelopmentErrorFallback error={error} componentStack={componentStack} />
    ) : (
      <Alert
        variant="negative"
        heading="Unexpected error"
        copy={
          <>
            An unexpected error has occurred. <RefreshOrContactSupportBoilerplate />
          </>
        }
      />
    )}
  </ErrorFallbackContainer>
);

const UnexpectedErrorFallbackDrawer = ({ componentStack, error }: ErrorFallbackProps) => (
  <Drawer>
    <DrawerHeader>
      <DrawerTitle>Unexpected error</DrawerTitle>
      <DrawerControls>
        <CloseDrawerButton />
      </DrawerControls>
    </DrawerHeader>
    <DrawerContent>
      <ErrorFallbackContainer>
        {import.meta.env.NODE_ENV === 'development' ? (
          <DevelopmentErrorFallback error={error} componentStack={componentStack} />
        ) : (
          <Alert
            variant="negative"
            copy={
              <>
                An unexpected error has occurred. Please try refreshing the page. If the issue
                persists, please <Link href="https://www.meter.com/support">contact support</Link>.
              </>
            }
          />
        )}
      </ErrorFallbackContainer>
    </DrawerContent>
  </Drawer>
);

const DisplayableErrorFallbackDrawer = ({
  error,
}: ErrorFallbackProps & { error: DisplayableError }) => (
  <Drawer>
    <DrawerHeader>
      <DrawerTitle>{error.displayTitle}</DrawerTitle>
      <DrawerControls>
        <CloseDrawerButton />
      </DrawerControls>
    </DrawerHeader>
    <DrawerContent>
      <Alert copy={error.displayMessage} />
    </DrawerContent>
  </Drawer>
);

export const ErrorFallbackDrawer = ({ error, componentStack }: ErrorFallbackProps) =>
  isDisplayableError(error) ? (
    <DisplayableErrorFallbackDrawer error={error} componentStack={componentStack} />
  ) : (
    <UnexpectedErrorFallbackDrawer error={error} componentStack={componentStack} />
  );
const Box = styled('div');

const FooterBoilerplate = () => (
  <Body2>
    Meter, Inc. • <Link href="https://www.meter.com/support">Support & FAQs</Link>
  </Body2>
);

export const FatalErrorFallback = ({ error, componentStack }: ErrorFallbackProps) => {
  const title = isDisplayableError(error) ? error.displayTitle : 'Something went wrong';
  const message = isDisplayableError(error) ? (
    error.displayMessage
  ) : (
    <>
      An unexpected error occurred. <RefreshOrContactSupportBoilerplate />
    </>
  );

  return import.meta.env.NODE_ENV === 'development' ? (
    <DevelopmentErrorFallback error={error} componentStack={componentStack} />
  ) : (
    <Box
      css={{
        display: 'flex',
        height: '100%',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Box css={{ vStack: '$32', maxWidth: 400, alignItems: 'stretch' }}>
        <Box css={{ vStack: '$20', alignItems: 'flex-start' }}>
          <Box css={{ vStack: '$4', alignItems: 'flex-start' }}>
            <Heading2>{title}</Heading2>
          </Box>
          <Body2>{message}</Body2>
        </Box>
        <FooterBoilerplate />
      </Box>
    </Box>
  );
};

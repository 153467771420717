/* eslint-disable react/no-unused-prop-types */

import type { FieldConfig, FieldProps } from 'formik/dist/Field';
import { Field } from 'formik';
import React from 'react';

import type { Paths, TypeAtPath } from '../types/paths';

/**
 * Enables type-safe Formik form fields that infer field types through Formik's
 * nested object path syntax. Does not support array/bracket notation.
 *
 * Due to limitations with how TypeScript infers generic parameter types, this
 * helper is implemented as a factory function that captures the Formik form's
 * FormValues type and returns a component.
 *
 * @example
 * interface FormData {
 *   nested: {
 *     value: string;
 *   }
 * }
 *
 * const TypedField = createTypedField<FormData>();
 *
 * const SomewhereInYourForm = () =>
 *   <TypedField name="nested.value"> // `nested.value` is type-checked
 *     {({ field }) => ...} // `field` has inferred type `FormikInputProps<string>`
 *   </TypedField>
 */
export const createTypedField = <FormValues extends object>() =>
  function TypedField<Name extends Paths<FormValues>>(
    props: {
      name: Name;
    } & Omit<FieldConfig<TypeAtPath<FormValues, Name>>, 'children'> & {
        // NOTE: Since we have the FormValues type, we can provide a more
        // specific annotation for the children prop than Formik usually
        // provides
        children?:
          | ((props: FieldProps<TypeAtPath<FormValues, Name>, FormValues>) => React.ReactNode)
          | React.ReactNode;
      },
  ) {
    return React.createElement(Field, props);
  };

import { MultiNavigationProvider } from '@meterup/react-router-extensions';
import * as Sentry from '@sentry/react';
import { ErrorBoundary } from '@sentry/react';
import { withLDProvider } from 'launchdarkly-react-client-sdk';
import React, { Suspense, useEffect, useState } from 'react';
import { ModalProvider, OverlayProvider } from 'react-aria';
import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { instanceOf, match } from 'ts-pattern';

import { NotFoundError } from '../errors';
import { useDrawerLocation } from '../hooks/useDrawerLocation';
import { useValidateFeatureFlags } from '../hooks/useValidateFeatureFlags';
import { ViewportProvider } from '../hooks/useViewport';
import { CurrentCompanyProvider } from '../providers/CurrentCompanyProvider';
import { CurrentControllerProvider } from '../providers/CurrentControllerProvider';
import { IdentityDataProvider } from '../providers/IdentityDataProvider';
import { routes } from '../routes';
import { colors, darkThemeSelector, globalCss, styled } from '../stitches';
import { isDefined } from '../utils/isDefined';
import { DashboardAppLayout } from './AppLayout/DashboardAppLayout';
import Clients from './Clients/Clients';
import Devices from './Devices/Devices';
import { AddClientDrawer } from './Drawers/AddClientDrawer/AddClientDrawer';
import { ClientDetailDrawer } from './Drawers/ClientDetailDrawer';
import { CreateTokenDrawer } from './Drawers/CreateTokenDrawer';
import { DeviceDetailDrawer } from './Drawers/DeviceDetailDrawer';
import { InviteUsersDrawer } from './Drawers/InviteUsersDrawer/InviteUsersDrawer';
import { NotFoundDrawer } from './Drawers/NotFoundDrawer';
import { SSIDDetailDrawer } from './Drawers/SSIDDetailDrawer';
import { TokenDetailDrawer } from './Drawers/TokenDetailDrawer';
import { UserDetailDrawer } from './Drawers/UserDetailDrawer/UserDetailDrawer';
import { FatalErrorFallback } from './ErrorFallback/ErrorFallback';
import ListTokens from './MeterAuth/ListTokens';
import NetworkSidebarDesktop from './Navigation/NetworkSidebarDesktop';
import NetworkSidebarMobile from './Navigation/NetworkSidebarMobile';
import { ScopedMobileSidebarToggle } from './Navigation/ScopedMobileSidebar';
import SettingsSidebarDesktop from './Navigation/SettingsSidebarDesktop';
import SettingsSidebarMobile from './Navigation/SettingsSidebarMobile';
import { networkSidebarEntries, settingsSidebarEntries } from './Navigation/sidebarEntries';
import { SidebarMainDrawerLayout } from './NetworkContainer/SidebarMainDrawerLayout';
import { OverlayableContainer, overlayableRootCSS } from './overlays';
import Overview from './Overview/Overview';
import InternetAndWireless from './Settings/InternetWiFi/InternetAndWireless';
import ListUsers from './Settings/Users/ListUsers';
import { NetworkSetup } from './Setup/NetworkSetup';
import { TabletJoinInstructions } from './TabletJoinInstructions';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: (count, error) =>
        match(error)
          .with(instanceOf(NotFoundError), () => false)
          .otherwise(() => true),
    },
  },
});

const injectGlobalStyles = globalCss({
  'html, body, #root': {
    height: '100%',
    width: '100%',
    overscrollBehaviorY: 'none',
    position: 'fixed',
    overflow: 'hidden',
  },
  body: {
    background: colors.white,
    fontSize: 16,
    [darkThemeSelector]: {
      background: colors['gray-800'],
    },
    // Enable improved font rendering only on high-DPI displays
    '@media only screen and (-webkit-min-device-pixel-ratio: 1.3), only screen and (-o-min-device-pixel-ratio: 13/10), only screen and (min-resolution: 120dpi)':
      {
        '-webkit-font-smoothing': 'antialiased',
        '-moz-osx-font-smoothing': 'grayscale',
      },
  },
  '#root': overlayableRootCSS,
});

const StyledOverlayProvider = styled(OverlayProvider, OverlayableContainer);

const DashboardApp = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const openMobileMenu = () => setIsMobileMenuOpen(true);
  const closeMobileMenu = () => setIsMobileMenuOpen(false);
  const drawerLocation = useDrawerLocation();

  return (
    <Switch>
      <Route path={routes.network.ssidInstructions.path} component={TabletJoinInstructions} exact />
      <DashboardAppLayout>
        <SidebarMainDrawerLayout
          mobileSidebarToggle={
            <Switch>
              <Route path={routes.settings.users.list.path}>
                <ScopedMobileSidebarToggle
                  isOpen={false}
                  entries={settingsSidebarEntries}
                  onClick={openMobileMenu}
                />
              </Route>
              <Route path={routes.network.overview.path}>
                <ScopedMobileSidebarToggle
                  isOpen={false}
                  entries={networkSidebarEntries}
                  onClick={openMobileMenu}
                />
              </Route>
            </Switch>
          }
          mobileSidebar={
            isMobileMenuOpen && (
              <Switch>
                <Route path={routes.settings.users.list.path}>
                  <SettingsSidebarMobile onClose={closeMobileMenu} />
                </Route>
                <Route path={routes.network.overview.path}>
                  <NetworkSidebarMobile onClose={closeMobileMenu} />
                </Route>
              </Switch>
            )
          }
          desktopSidebar={
            <Switch>
              <Route path={routes.settings.users.list.path}>
                <SettingsSidebarDesktop />
              </Route>
              <Route path={routes.network.overview.path}>
                <NetworkSidebarDesktop />
              </Route>
            </Switch>
          }
          main={
            <Switch>
              <Route path={routes.settings.users.list.path} component={ListUsers} />
              <Route path={routes.network.overview.path}>
                <Route path={routes.network.overview.path} component={Overview} exact />
                <Route path={routes.network.clients.list.path} component={Clients} exact />
                <Route path={routes.network.devices.list.path} component={Devices} exact />
                <Route
                  path={routes.network.internetWiFi.path}
                  component={InternetAndWireless}
                  exact
                />
                <Route path={routes.network.meterAuth.list.path} component={ListTokens} exact />
              </Route>
            </Switch>
          }
          drawer={
            isDefined(drawerLocation) && (
              <Switch location={drawerLocation}>
                <Route path={routes.drawers.clients.add.path} component={AddClientDrawer} />
                <Route
                  path={routes.drawers.clients.detail.path}
                  component={ClientDetailDrawer}
                  exact
                />
                <Route
                  path={routes.drawers.devices.detail.path}
                  component={DeviceDetailDrawer}
                  exact
                />
                <Route
                  path={routes.drawers.clientTokens.detail.path}
                  component={TokenDetailDrawer}
                  exact
                />
                <Route
                  path={routes.drawers.clientTokens.create.path}
                  component={CreateTokenDrawer}
                  exact
                />
                <Route path={routes.drawers.ssid.detail.path} component={SSIDDetailDrawer} exact />
                <Route path={routes.drawers.users.detail.path} component={UserDetailDrawer} />
                <Route
                  path={routes.drawers.users.invite.path}
                  component={InviteUsersDrawer}
                  exact
                />
                <Route path="*" component={NotFoundDrawer} />
              </Switch>
            )
          }
        />
      </DashboardAppLayout>
    </Switch>
  );
};

const DashboardAppProviders = ({ children }: { children: React.ReactNode }) => (
  <CurrentControllerProvider>
    <MultiNavigationProvider regionNames={['drawer']}>{children}</MultiNavigationProvider>
  </CurrentControllerProvider>
);

const AppProviders = ({ children }: React.PropsWithChildren<{}>) => (
  <Router>
    <QueryClientProvider client={queryClient}>
      <StyledOverlayProvider>
        <ModalProvider>
          <IdentityDataProvider>
            <CurrentCompanyProvider>
              <ViewportProvider>{children}</ViewportProvider>
            </CurrentCompanyProvider>
          </IdentityDataProvider>
        </ModalProvider>
      </StyledOverlayProvider>
    </QueryClientProvider>
  </Router>
);

const App: React.FC = () => {
  useEffect(() => {
    injectGlobalStyles();
  });

  useValidateFeatureFlags();

  return (
    <ErrorBoundary fallback={FatalErrorFallback}>
      <Suspense fallback={null}>
        <AppProviders>
          <Switch>
            <Route path={routes.setup.root.path} component={NetworkSetup} />
            <DashboardAppProviders>
              <DashboardApp />
            </DashboardAppProviders>
          </Switch>
        </AppProviders>
      </Suspense>
    </ErrorBoundary>
  );
};

const withLaunchDarkly = withLDProvider({
  clientSideID: import.meta.env.LAUNCHDARKLY_CLIENT_ID,
  options: {
    sendEvents: false,
  },
  reactOptions: {
    useCamelCaseFlagKeys: false,
  },
});

export default Sentry.withProfiler(withLaunchDarkly(App));

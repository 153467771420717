import type { BadgeVariant } from '@meterup/metric';
import {
  Badge,
  BasicSelect,
  BasicSelectItem,
  Body2,
  Box,
  Button,
  Heading,
  HStack,
  List,
  ListItem,
  ProviderIcon,
  ProviderIconProvider,
  ServiceButton,
  space,
  VStack,
} from '@meterup/metric';
import { useFormikContext } from 'formik';
import React, { useCallback, useState } from 'react';
import { useQuery } from 'react-query';
import { match } from 'ts-pattern';

import type { ProviderData } from '../../../../api/types';
import type { OnboardingFormData, ServiceProviderData, ServiceProviderType } from '../types';
import { fetchProviders } from '../../../../api/api';
import { isDefined } from '../../../../utils/isDefined';
import { FieldLabel } from '../../FieldLabel';
import { NextStepButton } from '../components/NextStepButton';
import { useOnboardingNavigation } from '../hooks/useOnboardingNavigation';

const formatISPSelection = (
  isp: ServiceProviderData,
  providerList: ProviderData[],
): { label: string; variant: BadgeVariant } =>
  match(isp)
    .with({ type: 'builtin' }, { type: 'other' }, (d) => {
      const provider = providerList.find((p) => p.sid === d.providerSid);
      return isDefined(provider)
        ? { label: provider.name, variant: 'brand' as const }
        : { label: 'Unknown', variant: 'neutral' as const };
    })
    .with({ type: 'no-isp' }, () => ({
      label: "We don't have an ISP",
      variant: 'neutral' as const,
    }))
    .exhaustive();

export const ISPDetailsStep: React.FC = () => {
  const { goToNextStep } = useOnboardingNavigation();

  const form = useFormikContext<OnboardingFormData>();

  const [isChanging, setIsChanging] = useState(form.values.serviceProviderData.type === 'no-isp');

  const setISP = useCallback(
    (type: ServiceProviderType, providerSid: string) => {
      form.setFieldValue('serviceProviderData', { type: 'builtin', providerSid });
      goToNextStep();
    },
    [form, goToNextStep],
  );

  const setNoISP = useCallback(() => {
    form.setFieldValue('serviceProviderData', { type: 'no-isp' });
    goToNextStep();
  }, [form, goToNextStep]);

  const providers = useQuery('providers', fetchProviders);

  const providerList = providers.data ?? [];
  const primaryProviderNameToIcon = {
    'AT&T': ProviderIconProvider.ATT,
    Comcast: ProviderIconProvider.ComcastBusiness,
    Verizon: ProviderIconProvider.Verizon,
  };

  const formattedSelection = formatISPSelection(form.values.serviceProviderData, providerList);
  return (
    <VStack spacing={space(16)}>
      <Heading>Get started with Meter Network</Heading>
      <List>
        <ListItem>
          <VStack
            width="full"
            role="group"
            aria-label="What is your current internet service provider?"
            spacing={space(12)}
          >
            <FieldLabel htmlFor="">What is your current internet service provider?</FieldLabel>
            {isChanging ? (
              <>
                {providerList
                  .filter((provider) => provider.name in primaryProviderNameToIcon)
                  .map((provider) => (
                    <ServiceButton
                      key={provider.sid}
                      icon={
                        <ProviderIcon
                          provider={
                            primaryProviderNameToIcon[
                              provider.name as keyof typeof primaryProviderNameToIcon
                            ]
                          }
                          color="adaptive"
                        />
                      }
                      label={provider.name}
                      arrangement="icon-only"
                      onClick={() => setISP('builtin', provider.sid)}
                    />
                  ))}
                <BasicSelect
                  aria-label="More providers"
                  controlSize="large"
                  onValueChange={(sid) => setISP('builtin', sid as string)}
                >
                  <BasicSelectItem value="" disabled>
                    More providers
                  </BasicSelectItem>
                  {providerList.map((item) => (
                    <BasicSelectItem key={item.sid} value={item.sid}>
                      {item.name}
                    </BasicSelectItem>
                  ))}
                </BasicSelect>
              </>
            ) : (
              <HStack align="center" justify="between">
                <Body2>Current selection</Body2>
                <Badge variant={formattedSelection.variant} size="large">
                  {formattedSelection.label}
                </Badge>
              </HStack>
            )}
          </VStack>
        </ListItem>
      </List>
      {isChanging ? (
        <Button variant="tertiary" size="large" width="full" onClick={() => setNoISP()}>
          We don't have an ISP
        </Button>
      ) : (
        <HStack justify="between">
          <Box width="full">
            <Button
              variant="tertiary"
              size="large"
              type="button"
              onClick={() => setIsChanging(true)}
            >
              Change
            </Button>
          </Box>
          <NextStepButton />
        </HStack>
      )}
    </VStack>
  );
};

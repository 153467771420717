import { List, ListItemHeader, ListTitle } from '@meterup/metric';
import React from 'react';

import type { DeviceData } from '../../api/types';
import { routes } from '../../routes';
import { DEVICE_LIST_COLUMNS } from '../../utils/deviceLists';
import { isDefinedAndNonEmpty } from '../../utils/isDefined';
import { ListItemTableContainer } from '../ListItemTableContainer';
import { Table } from '../Table/Table';

export const DeviceListWidget = ({ devices }: { devices: DeviceData[] }) => (
  <List>
    <ListItemHeader>
      <ListTitle>Devices</ListTitle>
    </ListItemHeader>
    <ListItemTableContainer>
      <Table
        columns={DEVICE_LIST_COLUMNS}
        data={devices}
        linkProps={(device) =>
          isDefinedAndNonEmpty(device.name)
            ? {
                to: {
                  main: routes.network.devices.list.path,
                  drawer: routes.drawers.devices.detail.pathTo(device.name),
                },
              }
            : null
        }
        emptyStateHeading="No devices"
        shouldHideGlobalSearch
      />
    </ListItemTableContainer>
  </List>
);

import { useQuery } from 'react-query';

import { fetchControllers } from '../../api/api';
import { useCurrentCompany } from '../../providers/CurrentCompanyProvider';

export const useControllerList = () => {
  const company = useCurrentCompany();

  const controllers = useQuery(['controllers_with_status', company], async () => {
    const controllersResp = await fetchControllers(company);
    return controllersResp;
  });

  return controllers.data ?? [];
};

import type { FormikProps } from 'formik';
import type React from 'react';
import { get } from 'lodash';

import type { Paths } from '../types/paths';
import { isDefined } from './isDefined';

export const getInputProps = <T extends object>(form: FormikProps<T>, key: Paths<T> & string) => {
  const formikInputProps = form.getFieldProps(key);

  return {
    ...formikInputProps,
    selectedKey: get(form.values, key),
    onChange: (eventOrValue: React.ChangeEvent | any) => {
      if (typeof eventOrValue === 'object') {
        form.setFieldValue(key, eventOrValue.target.value);
      } else {
        form.setFieldValue(key, eventOrValue);
      }
    },
    onValueChange: (v: string) => form.setFieldValue(key, v),
    onSelectionChange: (v: string) => form.setFieldValue(key, v),
    hasError: get(form.touched, key) && isDefined(get(form.errors, key)),
  };
};

export const getFieldProps = <T extends object>(form: FormikProps<T>, key: Paths<T>) => ({
  errorMessage: get(form.touched, key) ? get(form.errors, key) : undefined,
});

import type { CellProps, Column } from 'react-table';
import { Button, HStack, space } from '@meterup/metric';
import React, { useMemo } from 'react';
import { useQuery } from 'react-query';

import type { UserData } from '../../../api/types';
import { getCompanyUsers } from '../../../api/api';
import { useCloseDrawerCallback } from '../../../hooks/useCloseDrawerCallback';
import { useDrawerParams } from '../../../hooks/useDrawerParams';
import { useCurrentCompany } from '../../../providers/CurrentCompanyProvider';
import { routes } from '../../../routes';
import { formatFullName } from '../../../utils/names';
import { PendingBadge } from '../../badges';
import { Page, PageControls, PageHeader, PageSection, PageTitle } from '../../Page/Page';
import { SmartLink } from '../../SmartLink/SmartLink';
import { Table } from '../../Table/Table';
import { CellUserRole } from '../../Table/tableCells';
import { UserProfilePicture } from '../../UserProfilePicture';

const CellUserName = ({ value, row }: CellProps<UserData, string | null>) => (
  <HStack spacing={space(8)}>
    <UserProfilePicture user={row.original} /> {value ?? <PendingBadge />}
  </HStack>
);

const ListUsers = () => {
  const companySlug = useCurrentCompany();
  const drawerParams = useDrawerParams(routes.drawers.users.detail.path);
  const closeDrawer = useCloseDrawerCallback();

  const users = useQuery(['company_users', companySlug], () => getCompanyUsers(companySlug), {
    suspense: true,
  });

  const columns = useMemo(
    (): Column<UserData>[] => [
      {
        Header: 'Name',
        accessor: (row) => formatFullName(row),
        Cell: CellUserName,
      },
      {
        Header: 'Role',
        accessor: (row) => row.company_role,
        Cell: CellUserRole,
      },
      {
        Header: 'Email',
        accessor: (row) => row.email,
      },
    ],
    [],
  );

  return (
    <Page>
      <PageSection>
        <PageHeader>
          <PageTitle>Users</PageTitle>
          <PageControls>
            <Button
              icon="plusCircle"
              variant="secondary"
              as={SmartLink}
              to={{ drawer: routes.drawers.users.invite.path }}
            >
              Invite
            </Button>
          </PageControls>
        </PageHeader>
        <Table
          columns={columns}
          data={users.data!.users}
          linkProps={(user) => ({
            to: {
              drawer: routes.drawers.users.detail.pathTo(user.sid),
            },
          })}
          onRowDeselect={closeDrawer}
          isRowSelected={(user) => user.sid === drawerParams?.userSid}
          emptyStateHeading="No users"
        />
      </PageSection>
    </Page>
  );
};

export default ListUsers;

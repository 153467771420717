import { first } from 'lodash';
import React, { createContext, useContext, useEffect, useMemo } from 'react';
import { useLocation } from 'react-router';

import type { CompanyMembershipData } from '../api/types';
import { NoCompanyMembershipsError } from '../errors';
import { useLocalStorage } from '../hooks/useLocalStorage';
import { expectDefinedOrThrow } from '../utils/expectDefinedOrThrow';
import { useIdentity } from './IdentityDataProvider';

const SET_COMPANY_PARAM_KEY = 'current_company';

export const CurrentCompanyContext = createContext<CompanyMembershipData>(null as any);

export const CurrentCompanyProvider: React.FC = ({ children }) => {
  const identity = useIdentity();
  const location = useLocation();

  const slugInURLParams = useMemo(
    () => new URLSearchParams(location.search).get(SET_COMPANY_PARAM_KEY) ?? null,
    [location.search],
  );

  const [currentCompanySlug, setCurrentCompanySlug] = useLocalStorage<string | null>(
    'currentCompany',
    null,
  );

  const currentCompany =
    identity.company_memberships.find((c) => c.company_slug === slugInURLParams) ??
    identity.company_memberships.find((c) => c.company_slug === currentCompanySlug) ??
    first(identity.company_memberships);

  expectDefinedOrThrow(
    currentCompany,
    new NoCompanyMembershipsError(
      'No associated networks',
      `Your email address ${identity.username} is not associated with any Meter networks. If you believe this is an error, please reach out to your network administrator.`,
    ),
  );

  useEffect(() => {
    setCurrentCompanySlug(currentCompany.company_slug);
  }, [currentCompany.company_slug, setCurrentCompanySlug]);

  return (
    <CurrentCompanyContext.Provider value={currentCompany}>
      {children}
    </CurrentCompanyContext.Provider>
  );
};

export const useCurrentCompany = () => useContext(CurrentCompanyContext).company_slug;

export const useCurrentCompanyMembership = () => useContext(CurrentCompanyContext);

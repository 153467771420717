import { useRootLocation } from '@meterup/react-router-extensions';
import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import type { MainAndDrawerLocationDescriptor } from '../utils/main_and_drawer_navigation';
import { buildLocationDescriptorForMainAndDrawer } from '../utils/main_and_drawer_navigation';

export const useNavigateMainAndDrawerCallback = () => {
  const history = useHistory();
  const location = useRootLocation();

  return useCallback(
    (to: MainAndDrawerLocationDescriptor) => {
      history.push(buildLocationDescriptorForMainAndDrawer(to, location));
    },
    [history, location],
  );
};

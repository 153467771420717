import React from 'react';

import type { SidebarLocationControlInternalProps } from '../Sidebars/SidebarLocationControlInternal';
import { SidebarLocationControlInternal } from '../Sidebars/SidebarLocationControlInternal';

export interface MobileSidebarLocationControlProps extends SidebarLocationControlInternalProps {}

const MobileSidebarLocationControl: React.FC<MobileSidebarLocationControlProps> = ({
  size = 'medium',
  ...props
}) => <SidebarLocationControlInternal size={size} {...props} />;

export default MobileSidebarLocationControl;

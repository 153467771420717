import { Slot } from '@radix-ui/react-slot';
import React from 'react';

import type { To } from '../utils/locations';
import { useLink } from '../hooks/useLink';

interface LinkSlotProps {
  children: React.ReactNode;
  to: To<unknown>;
  replace?: boolean;
}

export const LinkSlot: React.FC<LinkSlotProps> = ({ children, to, replace = false }) => {
  const linkProps = useLink(to, replace);
  return <Slot {...linkProps}>{children}</Slot>;
};

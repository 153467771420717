import type React from 'react';

export interface StaticRouteDefinition {
  path: string;
  main: React.ComponentType;
  label: string;
}

export interface DynamicRouteDefinition<Args extends any[]> {
  path: string;
  pathTo: (...args: Args) => string;
  label: string;
  main: React.ComponentType;
}

export type RouteDefinition = StaticRouteDefinition | DynamicRouteDefinition<unknown[]>;

export const routes = {
  network: {
    overview: {
      path: '/',
      label: 'Overview',
    },
    meterAuth: {
      list: {
        path: '/meter-auth',
        label: 'Meter Auth',
      },
    },
    clients: {
      list: {
        path: '/clients',
        label: 'Clients',
      },
    },
    devices: {
      list: {
        path: '/devices',
        label: 'Devices',
      },
    },
    internetWiFi: {
      path: '/internet-wifi',
      label: 'Internet & wireless',
    },
    ssidInstructions: {
      path: '/join/:ssid',
      label: 'Join instructions',
      pathTo: (ssid: string) => `/join/${ssid}`,
    },
  },
  drawers: {
    clients: {
      detail: {
        path: '/clients/:macAddress',
        pathTo: (macAddress: string) => `/clients/${macAddress}`,
        label: 'Client',
      },
      add: {
        path: '/add-client',
        label: 'Add client',
      },
      addStandalone: {
        path: '/add-client/standalone',
        label: 'Add client',
      },
      addForUserStart: {
        path: '/add-client/user',
        label: 'Add client',
      },
      addForUser: {
        path: '/add-client/user/:userSid',
        pathTo: (userSid: string) => `/add-client/user/${userSid}`,
        label: 'Add client',
      },
    },
    devices: {
      detail: {
        path: '/devices/:deviceName',
        pathTo: (deviceName: string) => `/devices/${deviceName}`,
        label: 'Device',
      },
    },
    clientTokens: {
      create: {
        path: '/create-token',
        pathTo: () => `/create-token`,
        label: 'Create Token',
      },
      detail: {
        path: '/user/:userSid/tokens/:tokenSid',
        pathTo: (userSid: string, tokenSid: string) => `/user/${userSid}/tokens/${tokenSid}`,
        label: 'Client Token',
      },
    },
    users: {
      invite: {
        path: '/users/invite',
        label: 'Invite users',
      },
      detail: {
        path: '/user/:userSid',
        pathTo: (userSid: string) => `/user/${userSid}`,
        label: 'User',
      },
      remove: {
        path: '/user/:userSid/remove',
        pathTo: (userSid: string) => `/user/${userSid}/remove`,
        label: 'Remove user',
      },
    },
    ssid: {
      detail: {
        path: '/ssid/:ssidName',
        pathTo: (ssidName: string) => `/ssid/${ssidName}`,
        label: 'SSID',
      },
    },
  },
  settings: {
    users: {
      list: {
        path: '/settings/users',
        label: 'Users',
      },
    },
  },
  setup: {
    root: {
      path: '/setup',
      label: 'Setup Meter Network',
    },
    location: {
      root: {
        path: '/setup/:location',
        pathTo: (locationSid: string) => `/setup/${locationSid}`,
        label: 'Setup Meter Network',
      },
      form: {
        root: {
          path: '/setup/:location/form',
          pathTo: (locationSid: string) => `/setup/${locationSid}/form/isp`,
          label: 'Onboarding Form',
        },
        isp: {
          path: '/setup/:location/form/isp',
          pathTo: (locationSid: string) => `/setup/${locationSid}/form/isp`,
          label: 'ISP Setup',
        },
        mainNetwork: {
          path: '/setup/:location/form/main-network',
          pathTo: (locationSid: string) => `/setup/${locationSid}/form/main-network`,
          label: 'Network Setup',
        },
        guestNetwork: {
          path: '/setup/:location/form/guest-network',
          pathTo: (locationSid: string) => `/setup/${locationSid}/form/guest-network`,
          label: 'Guest Network',
        },
        meterInstall: {
          path: '/setup/:location/form/install',
          pathTo: (locationSid: string) => `/setup/${locationSid}/form/install`,
          label: 'Meter Install',
        },
      },
    },
  },
} as const;

const gatherRoutes = (
  routeOrBranch: RouteDefinition | object,
  currentKey = '',
): RouteDefinition[] => {
  if ('path' in routeOrBranch) {
    return [routeOrBranch];
  }

  return Object.entries(routeOrBranch)
    .map(([key, child]) => {
      const subKey = `${currentKey}.${key}`;
      return gatherRoutes(child, subKey);
    })
    .flat();
};

export const allRoutes = gatherRoutes(routes);

import type { Location } from 'history';
import type { match } from 'react-router';
import { useLocation } from 'react-router';

import type { PathMatchOptions, To } from '../utils/locations';
import { matchPathFromLocation } from '../utils/locations';

export interface IsActiveOptions extends PathMatchOptions {
  /**
   * A function to add extra logic for determining whether the link is active.
   */
  isActive?: (match: match<{}> | null, location: Location) => boolean;
}

export const useIsActive = (
  to: To<unknown>,
  { exact, sensitive, strict, isActive: isActiveFn }: IsActiveOptions = {},
) => {
  const currentLocation = useLocation();
  const matchedPath = matchPathFromLocation(to, currentLocation, {
    exact,
    sensitive,
    strict,
  });
  return !!(isActiveFn ? isActiveFn(matchedPath, currentLocation) : matchedPath);
};

/**
 * A hook that returns a function to determine whether a link is active.
 */
export const useIsActiveMatcher = () => {
  const currentLocation = useLocation();
  return (to: To<unknown>, options: IsActiveOptions = {}) =>
    !!matchPathFromLocation(to, currentLocation, options);
};

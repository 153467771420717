import type { SidebarLocationControlOption } from '@meterup/metric';
import {
  DesktopSidebar,
  DesktopSidebarLocationControl,
  DesktopSidebarLocationControlGroup,
  DesktopSidebarNavItem,
} from '@meterup/metric';
import { api } from '@meterup/proto';
import React from 'react';
import { Item } from 'react-stately';

import { useLogoutHandler } from '../../hooks/useLogoutHandler';
import {
  useChangeCurrentControllerCallback,
  useCurrentController,
} from '../../providers/CurrentControllerProvider';
import { styled } from '../../stitches';
import { ScopedDesktopSidebarEntries } from './ScopedDesktopSidebar';
import { networkSidebarEntries } from './sidebarEntries';
import { useControllerList } from './useControllerList';

const LogoutButton = styled('div', { marginTop: 'auto', width: '100%', padding: '$12' });

const NetworkSidebarDesktop: React.FC = () => {
  const logout = useLogoutHandler();
  const currentController = useCurrentController();
  const changeCurrentController = useChangeCurrentControllerCallback();

  const controllers = useControllerList();

  const dropDownOptions: SidebarLocationControlOption[] = controllers
    .flatMap((c) =>
      c.lifecycle_status === api.LifecycleStatus.LIFECYCLE_STATUS_INSTALLED_PRIMARY
        ? {
            value: c.name,
            label: c.address,
          }
        : [],
    )
    .sort((a, b) => a.label.localeCompare(b.label));

  return (
    <DesktopSidebar>
      <DesktopSidebarLocationControlGroup>
        <DesktopSidebarLocationControl
          aria-label="Choose a location"
          selectedKey={currentController}
          onSelectionChange={(key) => changeCurrentController(key as string)}
        >
          {dropDownOptions.map((item) => (
            <Item key={item.value}>{item.label}</Item>
          ))}
        </DesktopSidebarLocationControl>
      </DesktopSidebarLocationControlGroup>

      <ScopedDesktopSidebarEntries entries={networkSidebarEntries} />

      <LogoutButton>
        <DesktopSidebarNavItem as="button" label="Log out" onClick={logout} />
      </LogoutButton>
    </DesktopSidebar>
  );
};

export default NetworkSidebarDesktop;

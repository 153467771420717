import {
  List,
  ListItem,
  ListItemLabel,
  ListItemNavigationArrow,
  ListItemPair,
  ListItemValue,
} from '@meterup/metric';
import { sortBy } from 'lodash';
import React from 'react';
import { useQuery } from 'react-query';

import type { ClientData } from '../../api/types';
import { fetchClientConnectionHistory } from '../../api/api';
import { useCurrentController } from '../../providers/CurrentControllerProvider';
import { routes } from '../../routes';
import { isWireless } from '../../utils/clientLists';
import { SmartLink } from '../SmartLink/SmartLink';
import { CellClientLastSeenTime } from '../Table/tableCells';
import { WidgetHeading } from './WidgetHeading';

export const WirelessConnectionHistoryWidget = ({ client }: { client: ClientData }) => {
  const controller = useCurrentController();
  const data = useQuery(
    ['connection_history', controller, client.mac_address],
    () => fetchClientConnectionHistory(controller, client.mac_address),
    {
      suspense: true,
    },
  );

  const wirelessHistory = sortBy((data.data ?? []).filter(isWireless), 'last_seen')
    .reverse()
    .slice(0, 5);

  return wirelessHistory.length > 0 ? (
    <List>
      <ListItem>
        <WidgetHeading>Wireless history</WidgetHeading>
      </ListItem>
      {wirelessHistory.map((item) => (
        <ListItemPair
          as={SmartLink}
          to={{
            main: routes.network.devices.list.path,
            drawer: routes.drawers.devices.detail.pathTo(item.apname),
          }}
          key={item.last_seen}
        >
          <ListItemLabel>
            {item.ap_location.length > 0 ? item.ap_location : item.apname}
          </ListItemLabel>
          <ListItemValue>
            <CellClientLastSeenTime value={item.last_seen} />
          </ListItemValue>
          <ListItemNavigationArrow />
        </ListItemPair>
      ))}
    </List>
  ) : null;
};

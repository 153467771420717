import type { SidebarLocationControlOption } from '@meterup/metric';
import {
  MobileSidebar,
  MobileSidebarLocationControl,
  MobileSidebarLocationControlContainer,
  MobileSidebarNavItem,
  MobileSidebarToggleContainer,
  SidebarGroup,
} from '@meterup/metric';
import { api } from '@meterup/proto';
import React from 'react';
import { Item } from 'react-stately';

import {
  useChangeCurrentControllerCallback,
  useCurrentController,
} from '../../providers/CurrentControllerProvider';
import { MobileSidebarModal } from './MobileSidebarModal';
import { ScopedMobileSidebarEntries, ScopedMobileSidebarToggle } from './ScopedMobileSidebar';
import { networkSidebarEntries } from './sidebarEntries';
import { useControllerList } from './useControllerList';

interface LocationControlProps {
  onClose: () => void;
}

const LocationControl = ({ onClose }: LocationControlProps) => {
  const currentController = useCurrentController();
  const controllers = useControllerList();

  const dropDownOptions: SidebarLocationControlOption[] = controllers
    .flatMap((c) =>
      c.lifecycle_status === api.LifecycleStatus.LIFECYCLE_STATUS_INSTALLED_PRIMARY
        ? {
            value: c.name,
            label: c.address,
          }
        : [],
    )
    .sort((a, b) => a.label.localeCompare(b.label));

  const changeCurrentController = useChangeCurrentControllerCallback();

  const handleControllerSelect = (slug: string) => {
    changeCurrentController(slug);
    onClose();
  };

  return (
    <MobileSidebarLocationControl
      aria-label="Choose a location"
      selectedKey={currentController}
      onSelectionChange={(key) => handleControllerSelect(key as string)}
    >
      {dropDownOptions.map((item) => (
        <Item key={item.value}>{item.label}</Item>
      ))}
    </MobileSidebarLocationControl>
  );
};

interface NetworkSidebarMobileProps {
  onClose: () => void;
}

const NetworkSidebarMobile: React.FC<NetworkSidebarMobileProps> = ({ onClose }) => (
  <MobileSidebarModal onClose={onClose}>
    <MobileSidebar>
      <MobileSidebarToggleContainer>
        <ScopedMobileSidebarToggle entries={networkSidebarEntries} onClick={onClose} isOpen />
      </MobileSidebarToggleContainer>

      <ScopedMobileSidebarEntries entries={networkSidebarEntries} onClose={onClose} />

      <SidebarGroup>
        <MobileSidebarNavItem as="button" label="Log out" />
      </SidebarGroup>

      <MobileSidebarLocationControlContainer>
        <LocationControl onClose={onClose} />
      </MobileSidebarLocationControlContainer>
    </MobileSidebar>
  </MobileSidebarModal>
);

export default NetworkSidebarMobile;

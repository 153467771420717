import type { api } from '@meterup/proto';
import {
  Alert,
  Body2,
  Heading2,
  ListItemLabel,
  ListItemNavigationArrow,
  ListItemPair,
  ListItemValue,
} from '@meterup/metric';
import React from 'react';
import { useQuery } from 'react-query';

import { fetchUserClients, getUser } from '../../../api/api';
import { NotFoundError } from '../../../errors';
import { useDrawerParams } from '../../../hooks/useDrawerParams';
import { useCurrentCompany } from '../../../providers/CurrentCompanyProvider';
import { routes } from '../../../routes';
import { styled } from '../../../stitches';
import { getClientConnectionStatus } from '../../../utils/clientLists';
import { expectDefinedOrThrow } from '../../../utils/expectDefinedOrThrow';
import { isDefined } from '../../../utils/isDefined';
import { formatFullName } from '../../../utils/names';
import { OnlineOfflineWifiIconBadge, PendingBadge, UserRoleBadge } from '../../badges';
import { CollapsibleList } from '../../CollapsibleList';
import { SmartLink } from '../../SmartLink/SmartLink';
import { UserProfilePicture } from '../../UserProfilePicture';

const UserHeading = styled('div', {
  vStack: '$8',
  textAlign: 'center',
});

const UserNameAndEmail = styled('div', {
  vStack: 0,
});

export const UserDetails = () => {
  const userSid = useDrawerParams(routes.drawers.users.detail.path)!.userSid as string;
  const companySlug = useCurrentCompany();

  const userDetails = useQuery(
    ['user', companySlug, userSid],
    () => getUser(companySlug, userSid),
    {
      suspense: true,
    },
  ).data;

  expectDefinedOrThrow(userDetails, new NotFoundError('User not found'));

  // NOTE: It's technically possible for clients in this list to be associated
  // with a controller other than the user's currently selected controller. This
  // is a bug we consider unlikely to occur and have chosen not to handle right
  // now. This would manifest in a client that 404s when the user clicks on it.
  // There are other ways that behavior could happen, so this might not be the
  // cause.
  const clientsList =
    useQuery(['clients', userSid], () => fetchUserClients(userSid), {
      suspense: true,
    }).data ?? [];

  const formattedName = formatFullName(userDetails);

  return (
    <>
      {!formattedName && (
        <Alert
          icon="attention"
          heading="Pending details"
          copy="This user's profile information will sync when they first sign in using SSO."
        />
      )}
      <UserHeading>
        <UserProfilePicture user={userDetails} size="large" />
        {isDefined(formattedName) ? (
          <UserNameAndEmail>
            <Heading2>{formattedName}</Heading2>
            <Body2>{userDetails.email}</Body2>
          </UserNameAndEmail>
        ) : (
          <Heading2>{userDetails.email}</Heading2>
        )}
      </UserHeading>
      <CollapsibleList title="Profile & permissions" defaultOpen>
        <ListItemPair>
          <ListItemLabel>Name</ListItemLabel>
          <ListItemValue>
            <Body2>{formattedName ?? <PendingBadge />}</Body2>
          </ListItemValue>
        </ListItemPair>
        <ListItemPair>
          <ListItemLabel>Email</ListItemLabel>
          <ListItemValue>
            <Body2>{userDetails.email}</Body2>
          </ListItemValue>
        </ListItemPair>
        <ListItemPair>
          <ListItemLabel>Role</ListItemLabel>
          <ListItemValue>
            <UserRoleBadge role={userDetails.company_role} />
          </ListItemValue>
        </ListItemPair>
      </CollapsibleList>
      {clientsList.length > 0 && (
        <CollapsibleList title="Clients" defaultOpen>
          {clientsList.map((item) => (
            <ListItemPair
              as={SmartLink}
              to={{
                main: routes.network.clients.list.path,
                drawer: routes.drawers.clients.detail.pathTo(item.lease?.mac ?? ''),
              }}
              key={item.last_seen}
            >
              <ListItemLabel>{item.lease?.name}</ListItemLabel>
              <ListItemValue>
                <OnlineOfflineWifiIconBadge
                  status={getClientConnectionStatus(item as unknown as api.UserClient)}
                />
              </ListItemValue>
              <ListItemNavigationArrow />
            </ListItemPair>
          ))}
        </CollapsibleList>
      )}
    </>
  );
};

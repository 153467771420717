import type { Column } from 'react-table';
import { Button } from '@meterup/metric';
import React, { useMemo } from 'react';
import { useQuery } from 'react-query';

import type { TokenAndUser } from '../../api/api';
import { getAllTokenAndUsers } from '../../api/api';
import { useCloseDrawerCallback } from '../../hooks/useCloseDrawerCallback';
import { useDrawerParams } from '../../hooks/useDrawerParams';
import { useCurrentCompany } from '../../providers/CurrentCompanyProvider';
import { routes } from '../../routes';
import { Page, PageControls, PageHeader, PageSection, PageTitle } from '../Page/Page';
import { SmartLink } from '../SmartLink/SmartLink';
import { Table } from '../Table/Table';

const ListTokens = () => {
  const companySlug = useCurrentCompany();
  const drawerParams = useDrawerParams(routes.drawers.clientTokens.detail.path);
  const closeDrawer = useCloseDrawerCallback();

  const tokens = useQuery(
    ['company_token_and_users', companySlug],
    () => getAllTokenAndUsers(companySlug),
    {
      suspense: true,
    },
  );

  const columns = useMemo(
    (): Column<TokenAndUser>[] => [
      {
        Header: 'Email',
        accessor: (row) => row.user.email,
      },
      {
        Header: 'Alias',
        accessor: (row) => row.alias,
      },
      {
        Header: 'Expiry',
        accessor: (row) => row.expires_at,
      },
    ],
    [],
  );

  return (
    <Page>
      <PageSection>
        <PageHeader>
          <PageTitle>Client tokens</PageTitle>
          <PageControls>
            <Button
              icon="plusCircle"
              variant="secondary"
              as={SmartLink}
              to={{ drawer: routes.drawers.clientTokens.create.pathTo() }}
            >
              Create token
            </Button>
          </PageControls>
        </PageHeader>
        <Table
          columns={columns}
          data={tokens.data!}
          linkProps={(token) => ({
            to: {
              drawer: routes.drawers.clientTokens.detail.pathTo(token.user_sid, token.sid),
            },
          })}
          onRowDeselect={closeDrawer}
          isRowSelected={(token) => token.sid === drawerParams?.tokenSid}
          emptyStateHeading="No tokens"
        />
      </PageSection>
    </Page>
  );
};

export default ListTokens;

import React from 'react';

import type { SidebarLocationControlInternalProps } from '../Sidebars/SidebarLocationControlInternal';
import { SidebarLocationControlInternal } from '../Sidebars/SidebarLocationControlInternal';

export interface DesktopSidebarLocationControlProps extends SidebarLocationControlInternalProps {}

const DesktopSidebarLocationControl: React.FunctionComponent<
  DesktopSidebarLocationControlProps
> = ({ size = 'medium', ...props }) => <SidebarLocationControlInternal size={size} {...props} />;

export default DesktopSidebarLocationControl;

import type { MouseEvent } from 'react';
import { useCallback } from 'react';
import { useHistory, useLocation } from 'react-router';

import type { To } from '../utils/locations';
import { normalizeToLocation, resolveToLocation } from '../utils/locations';

const useHref = (to: To<unknown>) => {
  const history = useHistory();
  const location = useLocation();

  const resolvedLocation = resolveToLocation(to, location);
  const normalizedLocation = normalizeToLocation(resolvedLocation, location);

  return normalizedLocation ? history.createHref(normalizedLocation) : '';
};

const isModifiedEvent = (event: MouseEvent): boolean =>
  event.metaKey || event.altKey || event.ctrlKey || event.shiftKey;

const useClick = (to: To<unknown>, replace: boolean = false) => {
  const history = useHistory();
  const location = useLocation();

  const resolvedLocation = resolveToLocation(to, location);
  const method = replace ? history.replace : history.push;

  return useCallback(
    (event: MouseEvent) => {
      if (
        !event.defaultPrevented && // onClick prevented default
        event.button === 0 && // ignore everything but left clicks
        !isModifiedEvent(event) // ignore clicks with modifier keys
      ) {
        event.preventDefault();

        method(resolvedLocation);
      }
    },
    [resolvedLocation, method],
  );
};

/**
 * Provides props for accessible in-app navigation for use with an anchor tag
 *
 * Based on https://github.com/remix-run/react-router/issues/7390 to make it possible to use
 * react-router with components that already use an anchor tag.
 *
 * @param to A string, object, or function representing the link location
 * @param replace When true, clicking the link will replace the current entry in the history stack
 *  instead of adding a new one.
 * @returns href and onClick props to pass to an anchor tag
 */
export const useLink = (to: To<unknown>, replace = false) => ({
  href: useHref(to),
  onClick: useClick(to, replace),
});

import type { api } from '@meterup/proto';
import axios from 'axios';
import sortBy from 'lodash/sortBy';

import type {
  ClientData,
  DeviceData,
  DevicesData,
  IdentityData,
  ISPInfoData,
  LegacyControllerMessageData,
  LegacyNetworkInfoData,
  ListClientsData,
  LocationData,
  LocationsData,
  NetworkPlansData,
  OnboardingData,
  ProvidersData,
  SSIDData,
  StatusData,
  SuggestedPasswordData,
  UserData,
  UsersData,
  UsersTokensResponseJSON,
  UserTokenResponseJSON,
} from './types';
import { NotFoundError } from '../errors';
import { delay } from '../utils/delay';
import { isDefined } from '../utils/isDefined';
import { logError } from '../utils/logError';
import { retry } from '../utils/retry';

const API_BASE_URL = import.meta.env.REACT_APP_API_URL;

function nullIfNotFoundOrReThrow(error: unknown): null {
  if (axios.isAxiosError(error) && error.response?.status === 404) {
    return null;
  }

  logError(error);

  throw error;
}

function throwNotFoundErrorOrReThrow(error: unknown, errorIfNotFound: Error): null {
  if (axios.isAxiosError(error) && error.response?.status === 404) {
    throw errorIfNotFound;
  }

  logError(error);

  throw error;
}

const axiosInstance = axios.create({
  withCredentials: true,
  baseURL: `${API_BASE_URL}/api-proxy`,
});

export const removeDevices = (clients: api.UserClient[]) =>
  clients.filter((client) => !client.ip_address.startsWith('10.102'));

export const getIdentity = async () => {
  try {
    const response = await axiosInstance.get<IdentityData>('/v1/identity');
    return response.data;
  } catch (error) {
    if (
      axios.isAxiosError(error) &&
      (error.response?.status === 401 || error.response?.status === 403)
    ) {
      return null;
    }

    throw error;
  }
};

export const startNetworkInfoRequest = async (
  networkName: string,
): Promise<LegacyControllerMessageData | null> => {
  try {
    const response = await axiosInstance.get<LegacyControllerMessageData>(
      `/v1/dashboard/controllers/${networkName}/network-info?timeout=20s`,
    );
    return response.data;
  } catch (error) {
    logError(error);
    return null;
  }
};

const FAKE_NETWORK_INFO_RESPONSE = {
  data: {
    controller_name: 'mc0500010t012001',
    created_at: '2022-03-01T23:17:02.079023Z',
    message_sid: '4a2b99d3-3f5f-47c3-a46e-fff24771b51a',
    request:
      '{"action": "network_info", "module": "wireless", "params": {"time": "2022-03-01T23:17:02.078079872Z"}}',
    response: {
      version: 1,
      status: 'succeeded',
      error_id: '',
      error_text: '',
      data_id: 'network_info',
      data: '{"private_ssid":"Meter","private_2g_ssid":"Meter 2G","guest_ssid":"Meter Guest","private_password":"pizza2-party4","guest_password":"breakfast-tacos","guest_strategy":"DAILY"}',
    },
    finished_at: '2022-03-01T23:17:02.424216Z',
    links: {
      self: '/v1/dashboard/controllers/mc0500010t012001/messages/4a2b99d3-3f5f-47c3-a46e-fff24771b51a',
    },
  },
};

const fetchFromLegacyControllerLink = async <T extends any>(link: string): Promise<T> => {
  try {
    const response =
      import.meta.env.REALM === 'local'
        ? await delay(80).then(() => FAKE_NETWORK_INFO_RESPONSE)
        : await axiosInstance.get<LegacyControllerMessageData>(link, { timeout: 800 });
    return JSON.parse(response.data?.response?.data!);
  } catch (error) {
    logError(error);
    throw error;
  }
};

export const fetchNetworkInfo = async (controllerName: string): Promise<LegacyNetworkInfoData> => {
  try {
    const linkResponse = await startNetworkInfoRequest(controllerName);
    const link = linkResponse?.links?.self;
    if (link) {
      return await retry(() => fetchFromLegacyControllerLink<LegacyNetworkInfoData>(link), 7);
    }
    throw new Error('Failed to read network info');
  } catch (error) {
    logError(error);
    throw error;
  }
};

export const fetchNetworkISPInfo = async (company: string) => {
  try {
    return await axiosInstance
      .get<NetworkPlansData>(
        `${API_BASE_URL}/api-proxy/v1/dashboard/controllers/${company}/internet-service-plans`,
      )
      .then((data) => data?.data?.plans);
  } catch (error) {
    logError(error);
    return [];
  }
};

export const fetchISPInfo = async (providerSid: string) => {
  try {
    return await axiosInstance
      .get<ISPInfoData>(`${API_BASE_URL}/api-proxy/v1/providers/${providerSid}`)
      .then((data) => data?.data);
  } catch (error) {
    return nullIfNotFoundOrReThrow(error);
  }
};

export const fetchControllers = async (company: string): Promise<api.ControllerResponse[]> => {
  try {
    return await axiosInstance
      .get<api.CompanyControllersResponse>(`/v1/companies/${company}/controllers`)
      .then((data) => data?.data.controllers);
  } catch (error) {
    logError(error);
    return [];
  }
};

export const fetchStatus = async (controller: string): Promise<StatusData | null> => {
  try {
    return await axiosInstance
      .get(`/v1/dashboard/controllers/${controller}/status`)
      .then((data) => data?.data);
  } catch (error) {
    return nullIfNotFoundOrReThrow(error);
  }
};

export const fetchClients = async (controller: string): Promise<api.UserClient[]> => {
  try {
    return await axiosInstance
      .get<api.ClientListDashboardResponse>(`/v1/dashboard/controllers/${controller}/clients`)
      .then((data) => sortBy(data?.data?.clients, 'last_seen'))
      .then((data) => sortBy(data, [(d) => d.signal !== 0]).reverse())
      .then((data) => removeDevices(data));
  } catch (error) {
    logError(error);
    return [];
  }
};

export const fetchGuestClients = async (controller: string) => {
  try {
    return await axiosInstance
      .get(`/v1/dashboard/controllers/${controller}/clients?network=guest`)
      .then((data) => sortBy(data?.data?.clients, 'last_seen'))
      .then((data) => sortBy(data, [(d) => d.signal !== 0]).reverse())
      .then((data) => removeDevices(data));
  } catch (error) {
    logError(error);
    return [];
  }
};

export const fetchDevices = async (controller: string): Promise<DeviceData[]> => {
  try {
    const response = await axiosInstance.get<DevicesData>(
      `/v1/dashboard/controllers/${controller}/devices`,
    );

    const devices = (response?.data?.devices ?? []) as DeviceData[];
    return sortBy(devices, 'clients').reverse();
  } catch (error) {
    logError(error);
    return [];
  }
};

export const fetchDevice = async (
  controller: string,
  deviceName: string,
): Promise<DeviceData | null> => {
  try {
    const response = await axiosInstance.get<DevicesData>(
      `/v1/dashboard/controllers/${controller}/devices`,
    );

    const devices = (response?.data?.devices ?? []) as DeviceData[];
    return devices.find((device) => device.name === deviceName) ?? null;
  } catch (error) {
    return nullIfNotFoundOrReThrow(error);
  }
};

export const fetchDeviceClients = async (
  device: string,
  controller: string,
): Promise<ClientData[]> => {
  try {
    return await axiosInstance
      .get<ListClientsData>(`/v1/dashboard/controllers/${controller}/clients?ap=${device}`, {
        withCredentials: true,
      })
      .then((data) => data.data.clients);
  } catch (error) {
    logError(error);
    return [];
  }
};

export const fetchUserClients = async (userSid: string) => {
  const results = await axiosInstance.get<api.ConnectedClientsResponse>(
    `/v1/dashboard/company-users/${userSid}/clients`,
  );
  return results.data.clients;
};

export const fetchClientConnectionHistory = async (
  controller: string,
  mac: string,
): Promise<ClientData[]> => {
  try {
    return await axiosInstance
      .get(`/v1/dashboard/controllers/${controller}/clients?mac=${mac}`, { withCredentials: true })
      .then((data) => data?.data?.clients);
  } catch (error) {
    logError(error);
    return [];
  }
};

export const fetchDeviceConnectionHistory = async (
  controller: string,
  deviceName: string,
): Promise<ClientData[]> => {
  try {
    return await axiosInstance
      .get(`/v1/dashboard/controllers/${controller}/clients?ap=${deviceName}`, {
        withCredentials: true,
      })
      .then((data) => data?.data?.clients);
  } catch (error) {
    logError(error);
    return [];
  }
};

export const fetchFloorPlan = async (controller: string) => {
  try {
    return await axiosInstance
      .get(`/v1/dashboard/controllers/${controller}/floor-plan`, { responseType: 'blob' })
      .then((resp) => URL.createObjectURL(resp.data));
  } catch (error) {
    logError(error);
    return null;
  }
};

export const fetchClient = async (
  controller: string,
  clientSid: string,
): Promise<ClientData | null> => {
  try {
    return await axiosInstance
      .get(`/v1/dashboard/controllers/${controller}/clients/${clientSid}`)
      .then((data) => data.data);
  } catch (error) {
    return nullIfNotFoundOrReThrow(error);
  }
};

export const fetchProviders = async (): Promise<api.Provider[]> => {
  try {
    const response = await axiosInstance.get<ProvidersData>('/v1/providers');
    return response.data.providers;
  } catch (error) {
    logError(error);
    return [];
  }
};

export const getSuggestedPassword = async (wordCount: number) => {
  try {
    return (
      await axiosInstance.get<SuggestedPasswordData>('/v1/dashboard/password-suggestions', {
        params: {
          'word-count': wordCount,
        },
      })
    ).data.suggested_password;
  } catch (error) {
    return nullIfNotFoundOrReThrow(error);
  }
};

export const fetchCompanyLocations = async (companySlug: string) => {
  try {
    return (
      await axiosInstance.get<LocationsData>(`/v1/dashboard/companies/${companySlug}/locations`)
    ).data.locations.map((wrapper) => wrapper.location);
  } catch (error) {
    logError(error);
    return [];
  }
};

export const fetchCompanyLocation = async (
  companySlug: string,
  sublocationSid: string,
): Promise<LocationData | null> => {
  try {
    return (
      (
        await axiosInstance.get<LocationsData>(`/v1/dashboard/companies/${companySlug}/locations`)
      ).data.locations
        .map((wrapper) => wrapper.location)
        .find((location) => location.sublocation_sid === sublocationSid) ?? null
    );
  } catch (error) {
    return nullIfNotFoundOrReThrow(error);
  }
};

export const fetchOnboarding = async (companySlug: string, sublocationSid: string) => {
  try {
    return (
      await axiosInstance.get<OnboardingData>('/v1/onboardings', {
        params: {
          'company-slug': companySlug,
          'sublocation-sid': sublocationSid,
        },
      })
    ).data;
  } catch (error) {
    return nullIfNotFoundOrReThrow(error);
  }
};

export const updateOnboarding = async (data: Partial<OnboardingData>) => {
  try {
    return (await axiosInstance.put<OnboardingData>('/v1/onboardings', data)).data;
  } catch (error) {
    logError(error);
    return null;
  }
};

export const createUser = async (
  companySlug: string,
  email: string,
  companyRole: api.CompanyMembershipRole,
) => {
  try {
    return (
      await axiosInstance.post<UserData>(`/v1/dashboard/companies/${companySlug}/users`, {
        email,
        company_role: companyRole,
      })
    ).data;
  } catch (error) {
    logError(error);
    throw error;
  }
};

export const getCompanyUsers = async (companySlug: string) => {
  try {
    const response = await axiosInstance.get<UsersData>(
      `/v1/dashboard/companies/${companySlug}/users`,
    );

    return response.data;
  } catch (e) {
    if (axios.isAxiosError(e) && e.response?.status === 404) {
      return null;
    }

    logError(e);
    return null;
  }
};

export const getUserTokens = async (
  companyUserSid: string,
): Promise<UsersTokensResponseJSON | null> => {
  try {
    const response = await axiosInstance.get<UsersTokensResponseJSON>(
      `/v1/dashboard/company-users/${companyUserSid}/tokens`,
    );

    return response.data;
  } catch (e) {
    if (axios.isAxiosError(e) && e.response?.status === 404) {
      return null;
    }

    logError(e);
    return null;
  }
};

export const getToken = async (
  userSid: string,
  tokenSid: string,
): Promise<UserTokenResponseJSON | null> => {
  try {
    const response = await axiosInstance.get<UsersTokensResponseJSON>(
      `/v1/dashboard/company-users/${userSid}/tokens`,
    );

    return response.data.tokens.find((token) => token.sid === tokenSid) ?? null;
  } catch (e) {
    if (axios.isAxiosError(e) && e.response?.status === 404) {
      return null;
    }

    logError(e);
    return null;
  }
};

export const createToken = async (
  params: api.UserTokenCreateRequest,
): Promise<api.UserTokenResponse | null> => {
  try {
    const response = await axiosInstance.post<UserTokenResponseJSON>(
      `/v1/dashboard/company-users/${params.user_sid}/tokens`,
      params,
    );

    return response.data;
  } catch (e) {
    logError(e);

    throw e;
  }
};

export const getOrCreateSingleToken = async (
  userSid: string,
): Promise<UserTokenResponseJSON | null> => {
  try {
    const tokens = await getUserTokens(userSid);

    if (tokens?.tokens.length) {
      return tokens.tokens[0];
    }

    return await createToken({
      alias: 'Default token created in Dashboard',
      max_clients_limit: 10,
      user_sid: userSid,
    });
  } catch (e) {
    logError(e);

    throw e;
  }
};

export const deleteToken = async (userSid: string, tokenSid: string): Promise<void> => {
  try {
    const response = await axiosInstance.delete(
      `/v1/dashboard/company-users/${userSid}/tokens/${tokenSid}`,
    );

    return response.data;
  } catch (e) {
    logError(e);

    throw e;
  }
};

export const getUser = async (companySlug: string, userSid: string): Promise<UserData | null> => {
  try {
    const response = await axiosInstance.get<UserData>(
      `/v1/dashboard/companies/${companySlug}/users/${userSid}`,
    );

    return response.data;
  } catch (error) {
    return nullIfNotFoundOrReThrow(error);
  }
};

export const logoutUser = async (): Promise<void> => {
  try {
    await axiosInstance.post(`/v1/logout`);
  } catch (e) {
    logError(e);

    throw e;
  }
};

export const deleteUser = async (companySlug: string, userSid: string): Promise<void> => {
  try {
    await axiosInstance.delete(`/v1/dashboard/companies/${companySlug}/users/${userSid}`);
  } catch (e) {
    throwNotFoundErrorOrReThrow(e, new NotFoundError('User not found'));
  }
};

export interface TokenAndUser extends UserTokenResponseJSON {
  user: UserData;
}

export const getAllTokenAndUsers = async (companySlug: string): Promise<TokenAndUser[]> => {
  const companyUsers = await getCompanyUsers(companySlug);
  if (isDefined(companyUsers)) {
    return (
      await axios.all(
        companyUsers.users.map(async (user) =>
          ((await getUserTokens(user.sid))?.tokens ?? []).map((token) => ({
            ...token,
            user,
          })),
        ),
      )
    ).flat();
  }
  return [];
};

const KNOWN_STANDALONE_TOKEN_ALIAS = 'Default standalone token';

export const getOrCreateStandaloneToken = async (
  companySlug: string,
  userSid: string,
): Promise<UserTokenResponseJSON | null> => {
  try {
    const tokenAndUsers = await getAllTokenAndUsers(companySlug);
    const foundToken = tokenAndUsers.find((token) => token.alias === KNOWN_STANDALONE_TOKEN_ALIAS);

    if (isDefined(foundToken)) {
      return foundToken;
    }

    return await createToken({
      alias: KNOWN_STANDALONE_TOKEN_ALIAS,
      max_clients_limit: 100,
      user_sid: userSid,
    });
  } catch (e) {
    logError(e);

    throw e;
  }
};

// This function reshapes the network info data into a list of SSIDs. No
// endpoint exists that would return this data yet.
export async function fetchControllerSSIDs(controller: string): Promise<SSIDData[]> {
  const networkInfoData = await fetchNetworkInfo(controller);
  return isDefined(networkInfoData)
    ? [
        {
          sid: 'private',
          ssid: networkInfoData.private_ssid,
          password: { type: 'static', value: networkInfoData.private_password } as const,
          type: '5 GHz',
        },
        networkInfoData.private_2g_ssid
          ? {
              sid: 'private_2g',
              ssid: networkInfoData.private_2g_ssid,
              password: { type: 'static', value: networkInfoData.private_password } as const,
              type: '2.4 GHz',
            }
          : null,
        networkInfoData.guest_ssid
          ? {
              sid: 'guest',
              ssid: networkInfoData.guest_ssid,
              password: networkInfoData.guest_password
                ? ({
                    type: 'rotating',
                    value: networkInfoData.guest_password,
                    rotation_interval_name: networkInfoData.guest_strategy,
                  } as const)
                : null,
              type: 'Guest',
            }
          : null,
      ].filter(isDefined)
    : [];
}

export async function fetchControllerSSID(
  controller: string,
  sid: string,
): Promise<SSIDData | null> {
  const networkInfoData = await fetchControllerSSIDs(controller);
  return networkInfoData.find((ssid) => ssid.sid === sid) ?? null;
}

import { Box, Button } from '@meterup/metric';
import { useFormikContext } from 'formik';
import { get } from 'lodash';
import React, { useCallback } from 'react';

import type { Paths } from '../../../../types/paths';
import type { OnboardingFormData } from '../types';
import { useOnboardingNavigation } from '../hooks/useOnboardingNavigation';

export const NextStepButton: React.FC<{ requireValidationAtPath?: Paths<OnboardingFormData> }> = ({
  requireValidationAtPath,
}) => {
  const form = useFormikContext();
  const { goToNextStep } = useOnboardingNavigation();

  const validateOrGoToNextStep = useCallback(
    async (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();

      if (requireValidationAtPath) {
        const errors = await form.validateForm();
        if (!get(errors, requireValidationAtPath)) {
          goToNextStep();
        }
      } else {
        goToNextStep();
      }
    },
    [requireValidationAtPath, form, goToNextStep],
  );

  return (
    <Box width="full">
      <Button width="full" size="large" type="submit" onClick={validateOrGoToNextStep}>
        Next step
      </Button>
    </Box>
  );
};

import { BasicSelectItem, Button, space, VStack } from '@meterup/metric';
import { api } from '@meterup/proto';
import { Formik } from 'formik';
import React from 'react';
import { toFormikValidationSchema } from 'zod-formik-adapter';

import type { InviteUsersFormData } from './InviteUsersFormData';
import { useCloseDrawerCallback } from '../../../hooks/useCloseDrawerCallback';
import { routes } from '../../../routes';
import { styled } from '../../../stitches';
import { createTypedField } from '../../../utils/createTypedField';
import {
  CloseDrawerButton,
  Drawer,
  DrawerContent,
  DrawerControls,
  DrawerFooter,
  DrawerHeader,
  DrawerTitle,
} from '../../Drawer/Drawer';
import { BasicSelectField, TextAreaField } from '../units';
import { validInviteUsersFormData } from './InviteUsersFormData';

const StyledForm = styled('form', {
  height: '100%',
});

const TypedField = createTypedField<InviteUsersFormData>();

const InviteUsersField = () => (
  <TypedField name="emailListRawText">
    {({ field, form }) => (
      <VStack spacing={space(8)}>
        <TextAreaField
          label="Who else should have access?"
          name={field.name}
          value={field.value}
          onChange={(v) => form.setFieldValue(field.name, v)}
          onBlur={field.onBlur}
          placeholder="Email(s), comma or whitespace separated"
          errorMessage={form.touched.emailListRawText && form.errors.emailListRawText}
        />
      </VStack>
    )}
  </TypedField>
);

const RoleField = () => (
  <TypedField name="role">
    {({ field, form }) => (
      <VStack spacing={space(8)}>
        <BasicSelectField
          label="Choose a role"
          id={field.name}
          name={field.name}
          value={field.value}
          defaultValue={api.CompanyMembershipRole.member}
          onValueChange={(key) => form.setFieldValue(field.name, key)}
          errorMessage={form.touched.role && form.errors.role}
        >
          <BasicSelectItem value={api.CompanyMembershipRole.guest}>Guest</BasicSelectItem>
          <BasicSelectItem value={api.CompanyMembershipRole.member}>Member</BasicSelectItem>
          <BasicSelectItem value={api.CompanyMembershipRole.admin}>Admin</BasicSelectItem>
        </BasicSelectField>
      </VStack>
    )}
  </TypedField>
);

export const EmailsAndRoleForm = ({
  // eslint-disable-next-line react/prop-types
  initialValues,
  onSubmit,
}: {
  initialValues: InviteUsersFormData;
  onSubmit: (values: InviteUsersFormData) => void;
}) => {
  const closeDrawer = useCloseDrawerCallback();
  return (
    <Formik<InviteUsersFormData>
      validationSchema={toFormikValidationSchema(validInviteUsersFormData)}
      initialValues={initialValues}
      onSubmit={onSubmit}
    >
      {(form) => (
        <StyledForm onSubmit={form.handleSubmit}>
          <Drawer>
            <DrawerHeader>
              <DrawerTitle>{routes.drawers.users.invite.label}</DrawerTitle>
              <DrawerControls>
                <CloseDrawerButton />
              </DrawerControls>
            </DrawerHeader>
            <DrawerContent>
              <InviteUsersField />
              <RoleField />
            </DrawerContent>
            <DrawerFooter>
              <DrawerControls>
                <Button variant="secondary" type="button" onClick={closeDrawer}>
                  Cancel
                </Button>
                <Button type="submit" disabled={!form.isValid}>
                  Next
                </Button>
              </DrawerControls>
            </DrawerFooter>
          </Drawer>
        </StyledForm>
      )}
    </Formik>
  );
};

import {
  List,
  ListItem,
  ListItemLabel,
  ListItemNavigationArrow,
  ListItemPair,
  ListItemValue,
} from '@meterup/metric';
import { sortBy } from 'lodash';
import React from 'react';
import { useQuery } from 'react-query';

import type { DeviceData } from '../../api/types';
import { fetchDeviceConnectionHistory } from '../../api/api';
import { useCurrentController } from '../../providers/CurrentControllerProvider';
import { routes } from '../../routes';
import { isWireless } from '../../utils/clientLists';
import { SmartLink } from '../SmartLink/SmartLink';
import { CellClientLastSeenTime } from '../Table/tableCells';
import { WidgetHeading } from './WidgetHeading';

export const ClientConnectionHistoryWidget = ({ device }: { device: DeviceData }) => {
  const controller = useCurrentController();
  const data = useQuery(
    ['connection_history', controller, device.name],
    () => fetchDeviceConnectionHistory(controller, device.name),
    {
      suspense: true,
    },
  );

  const wirelessHistory = sortBy((data.data ?? []).filter(isWireless), 'last_seen').reverse();

  return wirelessHistory.length > 0 ? (
    <List>
      <ListItem>
        <WidgetHeading>Connected clients</WidgetHeading>
      </ListItem>
      {wirelessHistory.map((item) => (
        <ListItemPair
          as={SmartLink}
          to={{
            main: routes.network.clients.list.path,
            drawer: routes.drawers.clients.detail.pathTo(item.mac_address),
          }}
          key={item.last_seen}
        >
          <ListItemLabel>{item.name === '*' ? 'Unnamed device' : item.name}</ListItemLabel>
          <ListItemValue>
            <CellClientLastSeenTime value={item.last_seen} />
          </ListItemValue>
          <ListItemNavigationArrow />
        </ListItemPair>
      ))}
    </List>
  ) : null;
};

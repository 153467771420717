import {
  Button,
  List,
  ListItemLabel,
  ListItemPair,
  ListItemValue,
  PrimaryField,
  space,
  TextInput,
  VStack,
} from '@meterup/metric';
import { Formik } from 'formik';
import React from 'react';
import * as z from 'zod';
import { toFormikValidationSchema } from 'zod-formik-adapter';

import { FieldProvider } from '../FieldProvider';
import { FieldLabel } from './FieldLabel';
import { Drawer, DrawerContent, DrawerControls, DrawerHeader, DrawerTitle } from './layout';

export interface BillingSetupFormData {
  billingContactEmail: string;
}

const validBillingSetupFormData = z.object({ billingContactEmail: z.string().email() });

interface BillingDrawerProps {
  initialData?: BillingSetupFormData | null;
  isSaving?: boolean;
  onClose: () => void;
  onSubmit: (values: BillingSetupFormData) => void;
}

export const BillingDrawer = ({ initialData, isSaving, onClose, onSubmit }: BillingDrawerProps) => (
  <Formik<BillingSetupFormData>
    validationSchema={toFormikValidationSchema(validBillingSetupFormData)}
    initialValues={initialData ?? { billingContactEmail: '' }}
    onSubmit={onSubmit}
  >
    {(form) => (
      <Drawer>
        <form onSubmit={form.handleSubmit}>
          <DrawerHeader>
            <DrawerTitle>Billing setup</DrawerTitle>
            <DrawerControls>
              <Button variant="secondary" onClick={onClose} type="button" disabled={isSaving}>
                Cancel
              </Button>
              <Button type="submit" loading={isSaving}>
                Save
              </Button>
            </DrawerControls>
          </DrawerHeader>
          <DrawerContent>
            <VStack spacing={space(16)}>
              <List>
                <FieldProvider name="billingContactEmail">
                  <PrimaryField
                    label="Who manages your billing account?"
                    element={<TextInput placeholder="Email address" />}
                  />
                </FieldProvider>
              </List>
              <VStack spacing={space(8)}>
                <FieldLabel htmlFor="">Billing details</FieldLabel>
                <List>
                  <ListItemPair>
                    <ListItemLabel>Contact Name</ListItemLabel>
                    <ListItemValue>Meter Billing</ListItemValue>
                  </ListItemPair>
                  <ListItemPair>
                    <ListItemLabel>Email Address</ListItemLabel>
                    <ListItemValue>billing@meter.com</ListItemValue>
                  </ListItemPair>
                  <ListItemPair>
                    <ListItemLabel css={{ whiteSpace: 'nowrap' }}>Billing Address</ListItemLabel>
                    <ListItemValue css={{ textAlign: 'right' }}>
                      148 Townsend St, San Francisco CA, 94107
                    </ListItemValue>
                  </ListItemPair>
                  <ListItemPair>
                    <ListItemLabel>Phone</ListItemLabel>
                    <ListItemValue>888-912-1086</ListItemValue>
                  </ListItemPair>
                </List>
              </VStack>
            </VStack>
          </DrawerContent>
        </form>
      </Drawer>
    )}
  </Formik>
);

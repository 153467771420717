import type { UserData } from '../api/types';
import { isDefined } from './isDefined';

export const formatFullName = (user: UserData) => {
  const values = [user.first_name, user.last_name].filter(isDefined);

  if (values.length > 0) {
    return values.join(' ');
  }

  return null;
};

import type { Location, LocationDescriptorObject, LocationState, Path } from 'history';
import { buildMultiLocationDescriptor } from '@meterup/react-router-extensions';

/**
 * Describes a location object that can navigate to a `main` path and a `drawer`
 * path simultaneously. The `main` path is the path that is displayed in the
 * main content area of the page. The `drawer` path is the path that is
 * displayed in the drawer.
 *
 * If only the `main` path is provided, by default the `drawer` path (and all
 * other search params) will cleared unless `retainSearchParams` is true.
 */
export interface MainAndDrawerLocationDescriptor<State extends LocationState = unknown> {
  main?: Path | LocationDescriptorObject;
  drawer?: Path | LocationDescriptorObject;
  state?: State;
}

export const isMainAndDrawerLocationDescriptor = (
  value: Path | LocationDescriptorObject | MainAndDrawerLocationDescriptor,
): value is MainAndDrawerLocationDescriptor =>
  typeof value === 'object' && ('main' in value || 'drawer' in value);

export const buildLocationDescriptorForMainAndDrawer = (
  to: MainAndDrawerLocationDescriptor,
  location: Location,
): LocationDescriptorObject =>
  buildMultiLocationDescriptor(location, to.main, { drawer: to.drawer }, to.state);
